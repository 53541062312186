<template>
    <div class="edu-frame" style="background-color: rgb(239 239 239);">
        <edu-top-header></edu-top-header>
        <div class="edu-group" style="margin: 20px auto;">
            <div class="step-tips">您好，请修改您的密码！</div>
            <div class="step-tips-detail">请先进行手机验证再进行修改密码操作</div>
            <div class="class-form">
                <van-form @submit="editPwd">
<!--                    <van-field required v-model="formData.idCardNo" label="身份证号码" placeholder="请输入身份证号码" clearable :rules="[{ required: true, message: '请填写用户名' }]"/>-->
                    <van-field required v-model="formData.mobileTelNo" label="手机号码" placeholder="请输入手机号码" clearable :rules="[{ required: true, message: '请填手机号码' }]"></van-field>
                    <van-field required v-model="formData.verifyCode" label="验证码" placeholder="请输入验证码" clearable :rules="[{ required: true, message: '请填写验证码' }]">
                      <template #button>
                        <van-button :disabled="time > 0" size="small" block type="danger" native-type="button" @click="sendSms()">{{verifybtntxt}}</van-button>
                      </template>
                    </van-field>
                    <van-field required v-model="formData.password" type="password" label="密码" placeholder="请输入密码" clearable :rules="[{ required: true, message: '请填写密码' }]"/>
                    <van-field required v-model="formData.repassword" type="password" label="确认密码" placeholder="确认密码" clearable :rules="[{ required: true, message: '请填写确认密码' }]"/>
                    <div class="button-group">
                        <van-button size="small" block type="danger" native-type="button" @click="$router.go(-1);">上一步</van-button>
                        <van-button size="small" block type="danger" native-type="submit">确定</van-button>
                    </div>
                </van-form>
            </div>
        </div>
    </div>
</template>
<script>
    import EduTopHeader from "@/components/EduHeader";
    import {Form, Field, Button} from 'vant';
    import CustomerApi from "@/api/CustomerApi";

    export default {
        components: {
            EduTopHeader,
            VanForm: Form,
            VanField: Field,
            VanButton: Button
        },
        data() {
            return {
              time: 0,
              submitting: false,
              timeInterval: null,
              verifybtntxt: '获取验证码',
              formData: { },
              verify: { },
            }
        },
        name: 'editPwd',
        methods: {
          sendSms() {
            if (this.formData.mobileTelNo == null || this.formData.mobileTelNo == '') {
              this.$dialog.alert({message: "请输入手机号码"})
              return
            }

            var self = this
            self.time = 60
            self.timeInterval = setInterval(()=> {
              self.verifybtntxt = self.time + '秒后可重发'
              self.time--
              // console.log(self.time)
              if (self.time <= 0) {
                self.verifybtntxt = '获取验证码'
                self.time = 0
                clearInterval(self.timeInterval)
              }
            }, 1000)

            this.submitting = true
            CustomerApi.getPhoneCode({ mobile: this.formData.mobileTelNo }).then(response => {
              this.submitting = false
              this.verify = response.res
            }).then(() =>{ this.submitting = false })
          },
          editPwd() {
            /*if (this.formData.idCardNo == null || this.formData.idCardNo == '') {
              this.$dialog.alert({message: "请输入身份证号码。"})
              return
            }*/
            if (this.formData.mobileTelNo != this.verify.mobile) {
              this.$dialog.alert({message: "手机号码不是获取验证码的手机号码。"})
              return
            }
            if (this.formData.verifyCode != this.verify.verifyCode) {
              this.$dialog.alert({message: "验证码错误。"})
              return
            }
            if (this.formData.password == null || this.formData.password == '') {
              this.$dialog.alert({message: "请输入密码。"})
              return
            }
            if (this.formData.password != this.formData.repassword) {
              this.$dialog.alert({message: "两次密码输入不一致。"})
              return
            }

            this.submitting = true
            CustomerApi.veditPwd(this.formData).then(response => {
              this.submitting = false
              if (response.code == 100) {
                this.$router.push({ name: 'vlogin' })
              }
            }).catch(()=>{ this.submitting = false })

          }
        }
    }
</script>
<style scoped>
    .step-tips-detail {
        font-size: 14px;
        margin-left: 15px;
        margin-top: 10px;
        margin-bottom: 15px;

    }
    .form-tips{
        margin-top: 5px;
        margin-bottom: 5px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
    }

    .button-group {
        margin: 16px;
        display: flex;
    }
    .van-button {
        margin: 0 5px;
    }
</style>
